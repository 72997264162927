
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/attend-my-event/[event]",
      function () {
        return require("private-next-pages/attend-my-event/[event].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/attend-my-event/[event]"])
      });
    }
  