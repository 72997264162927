import { serverSideGetShopAvailQuery } from '../../generated/react-query';
import type { QueryClient } from '@tanstack/react-query';
import type { GetShopAvailQuery } from '../../generated/types';
import type { ShopSpecialRateInput } from '@dx-ui/gql-types';

export const spaRequest = (
  language: string,
  ctyhocn: string,
  arrivalDate: string,
  departureDate: string,
  numAdults: number,
  specialRates: ShopSpecialRateInput,
  client: QueryClient,
  guestLocationCountry: string | undefined
) =>
  new Promise<GetShopAvailQuery>(async (resolve, reject) => {
    try {
      const spaResult = await serverSideGetShopAvailQuery(client, {
        language,
        ctyhocn,
        arrivalDate,
        departureDate,
        numAdults,
        specialRates,
        guestLocationCountry,
      });
      resolve(spaResult);
    } catch (error) {
      // return gql related error
      reject(new Error(JSON.stringify(error)));
    }
  });
