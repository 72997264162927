import type * as React from 'react';
import PhoneIcon from '../../components/Icons/Phone';

type ContactInfoProps = {
  justifyContent?: string;
  title: string;
  numbers: {
    'data-testid': string;
    label: string;
    labelAccessibilityText: string;
    phone: string;
  }[];
  phone: string;
};

const ContactInfo: React.FC<React.PropsWithChildren<ContactInfoProps>> = (
  props
): React.ReactElement => {
  const { title, numbers, phone } = props;
  return (
    <div className="flex w-full flex-auto flex-col flex-nowrap justify-center pl-9 md:max-w-[350px] md:pl-14">
      <h3
        className="font-headline text-color-gray mb-4 inline-block text-lg font-bold md:text-2xl"
        data-testid="callSupport"
      >
        <span className="contact-info-heading-icon inline-block">
          <PhoneIcon />
        </span>
        {title}
      </h3>
      {numbers?.map((number) => (
        <p className="text-color-gray mb-2" key={number.label}>
          <span className="inline-block whitespace-pre-wrap">
            <span className="sr-only">{number.labelAccessibilityText}</span>
            <span aria-hidden="true">{number.label}</span>
          </span>
          <a className="text-color-gray inline-block md:hidden" href={`tel:${number.phone}`}>
            <span className="sr-only">{phone}</span>
            <span data-testid={number['data-testid']}>{number.phone}</span>
          </a>
          <span className="text-color-gray hidden md:inline-block">
            <span className="sr-only">{phone}</span>
            <span data-testid={number['data-testid']}>{number.phone}</span>
          </span>
        </p>
      ))}
    </div>
  );
};

export default ContactInfo;
