import type * as React from 'react';

export type IProps = {
  resortFeeText: string;
};

const ResortFeeDetails: React.FC<React.PropsWithChildren<IProps>> = ({ resortFeeText }) =>
  resortFeeText ? (
    <div className="resort-fee-desc bg-light-blue mb-6 mt-4 px-6 py-4" data-testid="fee-wrapper">
      <p className="text-lg font-semibold leading-normal">{resortFeeText}</p>
    </div>
  ) : null;

export default ResortFeeDetails;
