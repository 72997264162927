import { useState } from 'react';
import type { GetServerSideProps } from 'next';
import Head from '../../components/Head/Head';
import { Layout } from '../../components/Layout/Layout';
import CustomGroup from '../../components/CustomGroup/CustomGroup';
import Error from '../../components/ErrorDisplay/ErrorDisplay';
import { PageStatus } from '../../lib';
import type { PageStatusType } from '../../lib';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import nextI18nextConfig from '../../next-i18next.config';

const eventRegex =
  /^[A-Z]{5,7}-[0-9A-Z]{3}-[0-9A-F]{8}(?:-[0-9A-F]{4}){3}-[0-9A-F]{12}$|^[A-Z0-9-_]+$/i;

const CustomPage = () => {
  const [errorState, setErrorState] = useState<PageStatusType>(PageStatus.OK);

  if (errorState) {
    return <Error statusCode="404" />;
  }

  return (
    <>
      <Head>
        <meta name="robots" content="noindex, nofollow" />
      </Head>
      <Layout>
        <CustomGroup setErrorState={setErrorState} />
      </Layout>
    </>
  );
};

export const getServerSideProps = (async ({ locale, params }) => {
  const eventId = (params?.event as string) || '';

  if (!eventRegex.test(eventId)) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      ...(!!locale &&
        (await serverSideTranslations(
          locale,
          [
            'common',
            'osc-search',
            'osc-carousel',
            'osc-footer',
            'osc-header',
            'osc-date-picker',
            'osc-link',
            'osc-info-popup',
          ],
          nextI18nextConfig
        ))),
      eventId,
    },
  };
}) satisfies GetServerSideProps;

export default CustomPage;
